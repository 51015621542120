<template>
  <div id="information" class="container">
    <div class="user-info d-flex mb-4 align-items-center">
      <div class="d-flex flex-column">
        <img
          class="me-3 border-radius user-img"
          :src="
            user.Image
              ? user.Image
              : require('../../assets/images/profile/user_Profile.svg')
          "
          alt=""
          style="width: 140px; height: 140px"
        />
        <img
          src="../../assets/icons/nkicon/edit_profile.svg"
          class="edit-profile"
          @click="changePhotoDialog = true"
        />
      </div>
      <div>
        <div v-if="user.IsCarrier">
          <img
            src="../../assets/icons/nkicon/star.svg"
            alt=""
            style="margin-right: 5px"
          />
          {{ user.AvgRate ? Math.round(user.AvgRate * 10) / 10 : "Yok" }}
        </div>
        <div class="user-name fs-20 fw-bold">
          <span>{{ user.FirstName + " " + user.LastName }}</span>
        </div>
        <!-- <div class="user-city mb-2">
          <span v-if="user.City">{{ user.City.Title }}</span> <span>/</span>
          <span v-if="user.District">{{ user.District.Title }}</span>
        </div> -->
        <div class="information-btns">
        <span>Bayi Kodu: {{dealerData.DealerCode}}</span>
        </div>
      </div>
    </div>
    <b-tabs content-class="mt-3" v-model="step" justified>
      <b-tab title="Özet">
        <!-- <div class="row"> -->
        <div class="col-12">
          <div class="briefDetail ">


            <div>
              <h3>Bu Ayki Hedef Satış Cironuz:</h3>
              <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                  aria-valuenow="dealerData.ActualSaleRate" aria-valuemin="0" aria-valuemax="100" v-bind:style="{'width': dealerData.ActualSaleRate  + '%'}"></div>
              </div>
            </div>
            <div class="">
               <div>
                <p >Hedef Satış Gerçekleşen Oran : {{ dealerData.ActualSaleRate }} %</p>
                <p v-if="dealerData.TotalNakuAmount >= dealerData.Goal" style="background-color: darkgreen;color:white;font-weight:bold" class="text-center">TAMAMLANDI</p>
                <p v-else style="background-color: darkred;color:white;font-weight:bold" class="text-center">TAMAMLANMADI !</p>
              </div>
            </div>



          </div>

        </div>

        <div class="flex-container col-md-12">
          <div class="component-space col-md-3">
            <div class="card invoice-card" :class="'bg-light'">
              <div class="card-body d-flex">
                <div>
                  <h4 class="text-white invoice-num">
                    <strong>Aylık Bayi Satışları</strong> <br />
                    <!-- {{
                        // user.IsCarrier ? "Gelir" : "Gider"
                    }} -->
                  </h4>
                  <span class="text-white fs-18 float-end">{{dealerData.Sales}} ₺</span>
                </div>
              </div>
            </div>
          </div>
          <div class="component-space col-md-3">
            <div class="card invoice-card" :class="'bg-light'">
              <div class="card-body d-flex">
                <div>
                  <h4 class="text-white invoice-num">
                    <strong>NAKU Satış</strong> <br />
                  </h4>
                  <span class="text-white fs-18 float-end">{{dealerData.TotalNakuAmount}} ₺</span>
                </div>
              </div>
            </div>

          </div>
        
          <div class="component-space col-md-3">
            <div class="card invoice-card" :class="'bg-light'">
              <div class="card-body d-flex">
                <div>
                  <h4 class="text-white invoice-num">
                    <strong>Tahmini Kazanç</strong> <br />
                  </h4>
                  <span class="text-white fs-18 float-end">{{dealerData.EstimatedEarnings}} ₺</span>
                </div>
              </div>
            </div>
          </div>
          <div class="component-space col-md-3">
            <div class="card invoice-card" :class="'bg-light'">
              <div class="card-body d-flex">
                <div>
                  <h4 class="text-white invoice-num">
                    <strong>Hedef Tamamlanırsa Kazanç</strong> <br />

                  </h4>
                  <span class="text-white fs-18 float-end">{{dealerData.CompletedGain}} ₺</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-container col-md-12" :class="'space-around'">
         
        </div>



       <!--  <div class="apex-row">
          <div class="row-card">
            <div :class="{ 'col-xl-12': !user.IsCarrier, 'col-xl-9': user.IsCarrier }">
              <h3>{{ user.IsCarrier ? "Gelirler" : "Giderler" }}</h3>
              <not-found-info :item=null :title="user.IsCarrier ? 'Gelirler' : 'Giderler'" :emptyName="'none'" />
              <div v-if=true class="row">
                <div class="d-flex justify-content-end w-100">
                  <div class="d-flex justify-content-between w-40" style="width: 250px">
                  
                  </div>
                </div>
                <div class="col-12 p-0">
                  <apexchart type="line" height="300" :options="revenuesOpt" :series="revenuesSeries"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div> -->



      </b-tab>
      <b-tab title="Faturalarım" @click="
        () => {
          isWarehouse = false;
          this.firstRenderWarehouseDistrict = true;
        }
      ">
        
          <div class="row">
            <div v-if="bill.length < 1" class="
            col-md-12
            justify-content-between
            align-items-center
            depots
            bg-light
            border-radius
            py-2
            px-3
            mb-4">
            <span class="text-center" style="color:white;font-weight:bold;font-size:medium">Henüz Hiç Faturanız Yok! </span> 
             
  
            </div>
          <div v-else class="
              col-md-12
              d-flex
              justify-content-between
              align-items-center
              depots
              bg-light
              border-radius
              py-2
              px-3
              mb-4
            " v-for="(item, index) in bill" :key="index">
            <div class="depot-name fs-18">{{ item.date }} Tarihli Fatura</div>
            <div class="d-flex">
              <el-button circle @click="selectBill(item)"><img src="../../assets/images/edit.svg" alt="" /></el-button>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Bayilerim">
        <div class="row">
          <div v-if="dealerData.MemberDTOs.length < 1" class="
          col-md-12
          justify-content-between
          align-items-center
          depots
          bg-light
          border-radius
          py-2
          px-3
          mb-4">
          <span class="text-center" style="color:white;font-weight:bold;font-size:medium">Henüz Hiç Alt Bayiniz Yok!  
            <br/>
            <span style="color:darkred">"Alt Bayi Davet Et"</span> sekmesinden alt bayilerinizi davet ederek hemen kazanmaya başlayın!</span>

          </div>
          <div v-else class="
              col-md-12
              justify-content-between
              align-items-center
              depots
              bg-light
              border-radius
              py-2
              px-3
              mb-4
            " v-for="(item, index) in dealerData.MemberDTOs" :key="index">
            <div class="row">
              <div class="d-flex justify-content-between">
                <span>
                  <div style="font-size: x-large;">{{ item.Name }}</div>
                  <div class="depot-name fs-16">{{ item.RegistrationDate }}</div>
                </span>
                <span>
                  <div>
                    <img src="../../assets/icons/nkicon/adddetail.svg" alt="" style="margin-right: 30px; width: 60px" />
                  </div>
                </span>
              </div>
            </div>
            <br>

            <div class="row">
              <div class="col-6">
                <div class="
                  card
                  card-bayi
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-column
                  text-center
                  p-2
                ">
                  <p class="fs-20 text-white fw-normal">Toplam Naku Satın Alma </p>
                  <div class="depot-name fs-16">{{ item.totalNakuPurchase }}</div>
                </div>
              </div>
              <div class="col-6">
                <div class="
                  card
                  card-bayi
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-column
                  text-center
                  p-2
                ">
                  <p class="fs-20 text-white fw-normal">Toplam Kazanç </p>
                  <div class="depot-name fs-16"> {{ item.totalIncome }}</div>
                </div>

              </div>
              <div class="col-6">
                <div class="
                  card
                  card-bayi
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-column
                  text-center
                  p-2
                ">
                  <p class="fs-20 text-white fw-normal">Bu Ay Satın Alma </p>
                  <div class="depot-name fs-16">{{ item.monthlyNakuPurchase }}</div>

                </div>
              </div>
              <div class="col-6">
                <div class="
                  card
                  card-bayi
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-column
                  text-center
                  p-2
                ">
                  <p class="fs-20 text-white fw-normal">KDV Hariç Satın Alma </p>
                  <div class="depot-name fs-16">{{ item.monthlyNakuPurchaseWithoutKDV }}</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Alt Bayi Davet Et">
        <div class="row">
          <p class="text-blue">
            Alt bayiler oluşturarak NAKU kazanmaya başlayın!
          </p>
          <label>E-posta</label>
          <el-input
            class="form-control"
            :class="{
              'input-validation-error': inviteDealerResponse.ValidationErrors.some((x) => x.Key == 'Email'),
            }"
            placeholder="birisi@ornek.com"
            maxlength="60"
            v-model="invitecode"
            @keypress.enter.native="inviteDealer"
          ></el-input>
          <span class="validation-error" v-if="inviteDealerResponse.ValidationErrors.some((x) => x.Key == 'Email')">{{ inviteDealerResponse.ValidationErrors.filter((x) => x.Key == "Email")[0].Value }}</span>
          <div class="col-md-12 text-end" style="margin-top: 2%;">
            <el-button
              :loading="loading"
              @click="inviteDealer"
              class="bg-primary py-3 px-5"
              >Davet Gönder</el-button
            >
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <!-- Bu noktada güncelleme yapılacak -->
    <el-dialog :close-on-click-modal="false" :title="'Fatura Ekle'" :visible.sync="depotDialog"
      :before-close="clearDepotResponse">
      <span>
        <div class="billDetail">
          <div class="row">
            <div class="col-md-12 mt-2 mb-2">
              <span class="fs-18">Fatura Tarihi</span>
            </div>
            <div class="col-md-12">
              <div class="d-flex align-items-start flex-column text-left" style="margin-left: 15px;">
                <span style="font-size: 20px">{{ selectBill.date }}</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="detailDivider"></div>
            </div>
            <div class="col-md-12 mt-2 mb-2">
              <span class="fs-18">Bakiye</span>
            </div>
            <div class="col-md-12">
              <div class="d-flex align-items-start flex-column text-left" style="margin-left: 15px;">
                <span style="font-size: 20px">{{ selectBill.balance }}</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="detailDivider"></div>
            </div>
            <div class="col-md-12 mt-2 mb-2">
              <span class="fs-18">KDV Hariç Tutar</span>
            </div>
            <div class="col-md-12">
              <div class="d-flex align-items-start flex-column text-left" style="margin-left: 15px;">
                <span style="font-size: 20px">{{ selectBill.amounthWithoutKDV }}</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="detailDivider"></div>
            </div>
            <div class="col-md-12 mt-2 mb-2">
              <span class="fs-18">KDV Dahil Kesilecek Tutar</span>
            </div>
            <div class="col-md-12">
              <div class="d-flex align-items-start flex-column text-left" style="margin-left: 15px;">
                <span style="font-size: 20px">{{ selectBill.amounthWithKDV }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mb-2">
          <span slot="footer" class="dialog-footer">
            <label for="file-upload" class="custom-file-upload">
              <i class="fa fa-cloud-upload"></i> Fatura Seç
            </label>
            <input id="file-upload" type="file" />
            <el-button :loading="loading" type="file" class="py-3 px-5 col-md-12" @click="addInvoice">Fatura Ekle
            </el-button>
          </span>
        </div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { deepCopyObject } from "../../helpers/generalHelper";
import META_DATA from "../../constants/metaData";

// import notFoundInfo from "../../components/notFound/notFoundInfo.vue";




export default {
  // components: ,
  metaInfo() {
    return {
      title: META_DATA.PROFILE.Title,
    };
  },
  data() {
    return {
      //test data
      invitecode:null,
      dealerData: null,
      dealer: [
        {
          dealerName: "Bayi A",
          signInDate: "14.10.2022",
          totalNakuPurchase: 2300,
          totalIncome: 2500,
          monthlyNakuPurchase: 2300,
          monthlyNakuPurchaseWithoutKDV: 1800
        },
        {
          dealerName: "Bayi B",
          signInDate: "12.01.2021",
          totalNakuPurchase: 3000,
          totalIncome: 2400,
          monthlyNakuPurchase: 1200,
          monthlyNakuPurchaseWithoutKDV: 1000
        },
        {
          dealerName: "Bayi C",
          signInDate: "14.10.2022",
          totalNakuPurchase: 2300,
          totalIncome: 2500,
          monthlyNakuPurchase: 2300,
          monthlyNakuPurchaseWithoutKDV: 1800
        },
        {
          dealerName: "Bayi D",
          signInDate: "14.10.2022",
          totalNakuPurchase: 2300,
          totalIncome: 2500,
          monthlyNakuPurchase: 2300,
          monthlyNakuPurchaseWithoutKDV: 1800
        }
      ],

      bill: [
       /*  {
          date: "15.10.2022",
          balance: 6500,
          amounthWithoutKDV: 4500,
          amounthWithKDV: 1200
        },
        {
          date: "15.09.2022",
          balance: 4500,
          amounthWithoutKDV: 4124,
          amounthWithKDV: 1850
        },
        {
          date: "15.08.2022",
          balance: 5000,
          amounthWithoutKDV: 4200,
          amounthWithKDV: 3220
        },
        {
          date: "15.07.2022",
          balance: 6500,
          amounthWithoutKDV: 4500,
          amounthWithKDV: 1200
        }, */
      ],
      selectedBill: { date: null, balance: null, amounthWithoutKDV: null, amounthWithKDV: null },
      //test data
      location: "",
      places: [],
      selected: {},
      service: null,
      apiKey: "AIzaSyCjF3pCyknt07yWnY9N9d9MIPXkyZinAfg",
      step: 0,
      changePhotoDialog: false,
      deleteVehicleDialog: false,
      vehicleId: null,
      selectedVehicleType: null,
      firstRender: true,
      firstRenderBillDistrict: true,
      firstRenderWarehouseDistrict: true,
      isWarehouse: null,
      center: { lat: 45.508, lng: -73.587 },
      invoiceDialog: false,
      active: 1,
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
      ],
      value: "",
      nextbtn: "Geri",
      warehouses: null,
      WarehouseForm: {
        a: null,
        Title: null,
        City: null,
        District: null,
        Lat: 0,
        Lon: 0,
        Address: null,
        Phone: null,
      },
      warehouseFormDistricts: null,
      NotificationSettingsInputs: {},
      userFormInputs: {
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        CityTitle: null,
        DistrictTitle: null,
        Email: "",
        BankAccount: {},
        Address: "",
      },
      depotDialog: false,
      removeBlockDialog: false,
      depotResponse: { ValidationErrors: [] },
      resetObj: { ValidationErrors: [] },
      isUpdate: false,
    };
  },
  async created() {

    this.fetchData();
 /*    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.tab) {
      this.step = parseInt(params.tab);
      this.isWarehouse = this.step === 1 ? true : false;
    }
    await this.$store.dispatch("getCities");
    await this.$store.dispatch("getTrailers");
    //this.dealerInfo = this.$store.state.Dealer; //await this.$store.dispatch("getDealer");
    this.token = this.$store.getters["getToken"];
    console.log("Token:" + this.token);
    // const result = await this.$store.dispatch("getDistrict", this.user.CityTitle);

    // if (result)
    this.userFormInputs = deepCopyObject(this.user);
    console.log("this.$store.state.User: ", this.$store.state.User);
    console.log("this.$store.state.Dealer: ", this.$store.state.DealerDTO)
    this.warehouse = this.$store.state.User.warehouses;
    this.NotificationSettingsInputs = deepCopyObject(
      this.user.NotificationSettings
    );
    // this.personalInfoDistricts = await this.$getDistricts(
    //   this.userFormInputs.CityTitle
    // ); */
  },
  async mounted() {
    // this.MapsInit();
    this.$root.$emit("openAddDepotModal", () => {
      this.depotDialog = true;
      
    });
    
  },
  destroyed() {
    this.resetResponses();
  },
  computed: {

    cityInfo() {
      return this.$store.getters["cityInfo"];
    },
    token: {
      get() {
        return localStorage.getItem("token");
      },
      set(newValue) {
        return newValue;
      },
    },
  /*   warehouseId: {
      get() {
        return this.$store.getters["warehouseId"];
      },
      set(newValue) {
        this.$store.commit("warehouseId", newValue);
      },
    },
    userResponse: {
      get() {
        return this.$store.getters["userResponse"];
      },
      set(newValue) {
        this.$store.commit("userResponse", newValue);
      },
    }, */
   /*  invoiceResponse: {
      get() {
        return this.$store.getters["invoiceResponse"];
      },
      set(newValue) {
        this.$store.commit("invoiceResponse", newValue);
      },
    },
    */
  
    user: {
      get() {
        return this.$store.getters["userState"];
      },
      set(newValue) {

        this.$store.commit("userCommit", newValue);
      },
    }, 
    inviteDealerResponse: {
      get() {
        return this.$store.getters["inviteDealerResponse"];
      },
      set(newValue) {
        this.$store.commit("inviteDealerResponse", newValue);
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
 /*  watch: {
    WarehouseForm: function () {
      console.log("this.WarehouseForm change", this.WarehouseForm);
    },
    step: {
      handler: function () {
        this.resetResponses();
      },
      deep: true,
    },
  }, */
  methods: {


    clearForm: function () {
      this.location = "";
      this.selected = {};
      this.places = [];
      this.$emit("selectedArea", {});
      this.geolocate();
    },
    fetchData(){
      this.$store.dispatch("getDealer")
      .then(() => {
        console.log("fetchDataResponse", this.$store.getters.getDealerData);
        this.dealerData = this.$store.getters.getDealerData;
      })
      .catch((error) => {console.error('Veri Çekme hatası :' + error);});
    },
    addInvoice(){
       console.log("addInvoice Burada Tetiklenecek!");
    },
    clearDepotResponse() {
      this.depotResponse = this.resetObj;
      this.depotDialog = false;
      this.location = "";
    },
    resetResponses() {
      this.vehicleResponse = this.resetObj;
      this.bankResponse = this.resetObj;
      this.depotResponse = this.resetObj;
      this.userResponse = this.resetObj;
      this.invoiceResponse = this.resetObj;
      this.changePasswordResponse = this.resetObj;
      this.inviteDealerResponse = this.resetObj;
    },
    async inviteDealer() {
      this.$store.dispatch("inviteDealer", {
        Email: this.invitecode,
      });
    },
    newWarehouse() {
      this.depotDialog = true;
      this.warehouseFormDistricts = null;
      this.WarehouseForm = {
        Title: null,
        City: null,
        District: null,
        Lat: 0,
        Lon: 0,
        Address: null,
        Phone: null,
      };
      this.geolocate();
    },
    // eslint-disable-next-line no-debugger
    selectWarehouse(warehouse) {
      this.marker.position.lng = warehouse.Lon;
      this.marker.position.lat = warehouse.Lat;
      this.center.lat = warehouse.Lat;
      this.center.lng = warehouse.Lon;
      this.depotDialog = true;
      this.location = warehouse.Address;
      warehouse.City = warehouse.CityTitle;
      warehouse.District = warehouse.DistrictTitle;
      this.WarehouseForm = deepCopyObject(warehouse);
    },

    selectBill(bill) {
      this.depotDialog = true;
      this.warehouseFormDistricts = null;
      this.selectBill.date = bill.date;
      this.selectBill.balance = bill.balance;
      this.selectBill.amounthWithoutKDV = bill.amounthWithoutKDV;
      this.selectBill.amounthWithKDV = bill.amounthWithKDV;
    },



    next() {
      if (this.active++ > 1) this.active = 1;

      if (this.active === 2) {
        this.nextbtn = "Geri";
      } else {
        this.nextbtn = "Geri";
      }
    },

  },
};
</script>

<style lang="scss">
@import "./style.scss";

.briefDetail {
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
}



.billDetail {
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.detailDivider {
  border-bottom: 1px dashed white;
  margin: 15px 0;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

#extra {
  position: absolute;
  left: 20%;
  right: 0;
  min-width: 50px;
  max-width: 80%;
  height: auto;

}

.invoice-card-row {
  .col-xl-9 {
    display: flex;
    justify-content: space-between;

    .card-container {
      width: 33%;
    }
  }
}

.row-card {
  padding: 0;
  margin-top: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.card-body {
  padding: 20px !important;
}


.apexcharts-legend-series .apexcharts-legend-text {
  color: #fff !important;
}

.apex-row {
  .col-xl-9 {
    margin-bottom: 1.875rem;

    .chart {
      border: 1px solid transparent;
      border-radius: 16px;
      background-color: #4d668a;
      padding: 15px;
      height: 100% !important;
    }
  }
}

.apexcharts-toolbar {
  z-index: 0 !important;
}
</style>
